/**
 * Default breakpoints which are used with the components.
 * Separate breakpoint list can also be used if different values are required.
 * Modifying these valus will change how some components behave with different screen sizes.
 *
 * The units here are in em (base font size 16px) because Safari has some issues with breakpoints defined in rem.
 * (https://zellwk.com/blog/media-query-units/)
 *
 * Use these with media queries as follows:
 * @example
 *
 *  const Component = styled.div`
 *    width: 100%;
 *    @media only screen and (max-width: ${breakpoint.xs}) {
 *      width: 50%;
 *    }
 *  `
 */

export const breakpoint = {
  /**
   * 20em === 320px
   */
  xs: "20em",
  /**
   * 23.4375em === 375px
   */
  sm: "23.4375em",
  /**
   * 48em === 768px
   */
  md: "48em",
  /**
   * 64em === 1024px
   */
  lg: "64em",
  /**
   * 80em === 1280px
   */
  xl: "80em",
  /**
   * 100em === 1600px
   */
  xxl: "100em",
};

/**
 * Color values that are used to define the color theme
 * Source: https://www.figma.com/file/HCUXm1in1KMkRpinopcA0I/Posti-DS---UI-Kit-0.5
 */
export const color = {
  /**
   * =========== Neutral colors ===========
   */

  /**
   * #262C30
   */
  neutralBlack: "#262C30",
  /**
   * #3B4A57
   */
  neutralNetworkGray: "#3B4A57",
  /**
   * #73808C
   */
  neutralIconGray: "#73808C",
  /**
   * #C2C9D1
   */
  neutralPassiveGray: "#C2C9D1",
  /**
   * #E6EBF1
   */
  neutralGray5: "#E6EBF1",
  /**
   * #F6F7F9
   */
  neutralGray2: "#F6F7F9",
  /**
   * #FFFFFF
   */
  neutralWhite: "#FFFFFF",
  /**
   * #EAEDF1
   */
  neutralOnPressGray: "#EAEDF1",

  /**
   * =========== Brand colors ===========
   */

  /**
   * #7D2332
   */
  brandRed: "#7D2332",
  /**
   * #FFA894
   */
  brandPeach: "#FFA894",
  /**
   * #EDBDBD
   */
  brandPink: "#EDBDBD",
  /**
   * #FFC226
   */
  brandBrightYellow: "#FFC226",
  /**
   * #F7CC7A
   */
  brandMutedYellow: "#F7CC7A",
  /**
   * #D9BFAD
   */
  brandCardboard: "#D9BFAD",
  /**
   * #5C2952
   */
  brandPurple: "#5C2952",
  /**
   * #1E3264
   */
  brandNavy: "#1E3264",
  /**
   * #A6EBEC
   */
  brandSky: "#A6EBEC",
  /**
   * #29545C
   */
  brandPetrol: "#29545C",
  /**
   * #38572B
   */
  brandForest: "#38572B",

  /**
   * =========== Signal colors ===========
   */

  /**
   * #0051C2
   */
  signalBlue: "#0051C2",
  /**
   * #008A00
   */
  signalGreen: "#008A00",
  /**
   * #E0F3D7
   */
  signalLightGreen: "#E0F3D7",
  /**
   * #F9F4EB
   */
  signalLightYellow: "#F9F4EB",
  /**
   * #BD2841
   */
  signalRed: "#BD2841",
  /**
   * #F8E7E7
   */
  signalLightRed: "#F8E7E7",
  /**
   * #D4E2F7
   */
  signalLightBlue: "#D4E2F7",
  /**
   * #004099
   */
  signalHoverBlue: "#004099",
  /**
   * #F7C3C3
   */
  signalHoverRed: "#F7C3C3",
};

/**
 * Common values for spacings (margins, paddings etc.). The units are in rem (base font size 16px).
 */
export const spacing = {
  /**
   * 0.5rem === 8px
   */
  xxs: 0.5,
  /**
   * 1rem === 16px
   */
  xs: 1,
  /**
   * 1.5rem === 24px
   */
  sm: 1.5,
  /**
   * 2rem === 32px
   */
  md: 2,
  /**
   * 3rem === 48px
   */
  lg: 3,
  /**
   * 4rem === 64px
   */
  xl: 4,
  /**
   * 6rem === 96px
   */
  xxl: 6,
};

/**
 * Main font family defitions with fallback fonts
 */
export const fontFamily = {
  /**
   * Primary font for UI text and titles
   */
  primary: "Montserrat, sans-serif",
  /**
   * Altenative font to use in e.g. hero elements and in page headings
   */
  secondary: "Posti Sans, sans-serif",
};

/**
 * Common font sizes from small text to the largest
 * header style. Units are in rem (base font size 16px). Pair these with line-heights
 * defined in the theme.
 *
 *  @example
 *  import styled, { css } from 'styled-components'
 *
 *  const LargeText = styled.p(({ theme }) => css`
 *    font-family: ${theme.fontFamily.primary};
 *    font-weight: ${theme.fontWeight.normal};
 *    line-height: ${theme.lineHeight.lg}rem;
 *    font-size: ${theme.fontSize.lg}rem;
 *  `)
 */
export const fontSize = {
  text: {
    /**
     * 1.25rem === 20px
     */
    xl: 1.25,
    /**
     * 1.125rem === 18px
     */
    lg: 1.125,
    /**
     * 1rem === 16px
     */
    md: 1,
    /**
     * 0.875rem === 14px
     */
    sm: 0.875,
    /**
     * 0.8125rem === 13px
     */
    xs: 0.8125,
  },
  title: {
    /**
     * 3.5rem === 56px
     */
    xxxl: 3.5,
    /**
     * 3rem === 48px
     */
    xxl: 3,
    /**
     * 2.4375rem === 39px
     */
    xl: 2.4375,
    /**
     * 1.96875rem === 31.5px
     */
    lg: 1.96875,
    /**
     * 1.5625rem === 25px
     */
    md: 1.5625,
    /**
     * 1.125rem === 18px
     */
    sm: 1.125,
    /**
     * 1rem === 16px
     */
    xs: 1,
  },
};

/**
 * Common line heights for text styles. Units are in rem (base font size 16px).
 */
export const lineHeight = {
  text: {
    /**
     * 2rem === 32px
     */
    xl: 2,
    /**
     * 1.75rem === 28px
     */
    lg: 1.75,
    /**
     * 1.5rem === 24px
     */
    md: 1.5,
    /**
     * 1.125rem === 18px
     */
    sm: 1.125,
    /**
     * 1.25rem === 20px
     */
    xs: 1.25,
  },
  title: {
    /**
     * 3.5rem === 56px
     */
    xxl: 3.5,
    /**
     * 2.5rem === 40px
     */
    xl: 2.5,
    /**
     * 2.25rem === 36px
     */
    lg: 2.25,
    /**
     * 1.875rem === 30px
     */
    md: 1.875,
    /**
     * 1.375rem === 22px
     */
    sm: 1.375,
    /**
     * 1.25rem === 20px
     */
    xs: 1.25,
  },
};

/**
 * Common font weights that have been configured to work for all defined font families
 */
export const fontWeight = {
  /**
   * 100
   */
  thin: 100,
  /**
   * 300
   */
  light: 300,
  /**
   * 400
   */
  normal: 400,
  /**
   * 500
   */
  medium: 500,
  /**
   * 600
   */
  semiBold: 600,
  /**
   * 700
   */
  bold: 700,
  /**
   * 900
   */
  ultraBold: 900,
};

/**
 * Predefined sizes for specific component types. Sizes are specified as em or rem units with base font size 16
 */
export const size = {
  icon: {
    /**
     * 0.813 rem === 13px
     */
    xxs: 0.813,
    /**
     * 1 rem === 16px
     */
    xs: 1,
    /**
     * 1.5 rem === 24px
     */
    s: 1.5,
    /**
     * 2 rem === 32px
     */
    m: 2,
    /**
     * 2.5 rem === 40px
     */
    l: 2.5,
    /**
     * 3 rem === 48px
     */
    xl: 3,
    /**
     * 4 rem === 64px
     */
    xxl: 4,
  },
};

export const borderRadius = {
  md: 0.25,
};

export const colors = {
  /**
   * Semantic colors - colors with meaning embedded in them, instances where the specific color is more important than being a part of the brand
   */
  semantic: {},
  /**
   * Layout colors - colors describing how the common layout sections should be colored
   */
  layout: {
    /**
     * Neutral network gray
     */
    header: color.neutralNetworkGray,
  },
  /**
   * UI colors used in e.g. form controls and atom level components with also states defined
   */
  ui: {},
};

export const PostiTheme = {
  breakpoint,
  color,
  spacing,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  size,
  borderRadius,
  colors,
  name: "posti",
};
