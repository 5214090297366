export const XyzTheme = {
  /**
   * Default breakpoints which are used with the components.
   * Separate breakpoint list can also be used if different values are required.
   * Modifying these valus will change how some components behave with different screen sizes.
   *
   * The units here are in em (base font size 16px) because Safari has some issues with breakpoints defined in rem.
   * (https://zellwk.com/blog/media-query-units/)
   *
   * Use these with media queries as follows:
   * @example
   *
   *  const Component = styled.div`
   *    width: 100%;
   *    @media only screen and (max-width: ${breakpoint.xs}) {
   *      width: 50%;
   *    }
   *  `
   */
  breakpoint: {
    /**
     * 20em === 320px
     */
    xs: "20em",
    /**
     * 23.4375em === 375px
     */
    sm: "23.4375em",
    /**
     * 48em === 768px
     */
    md: "48em",
    /**
     * 64em === 1024px
     */
    lg: "64em",
    /**
     * 80em === 1280px
     */
    xl: "80em",
    /**
     * 100em === 1600px
     */
    xxl: "100em",
  },
  spacing: {
    /**
     * 0rem === 0px
     */
    space0: 0,
    /**
     * 0.25rem === 4px
     */
    space1: 0.25,
    /**
     * 0.5rem === 8px
     */
    space2: 0.5,
    /**
     * 1rem === 16px
     */
    space3: 1,
    /**
     * 1.5rem === 24px
     */
    space4: 1.5,
    /**
     * 2rem === 32px
     */
    space5: 2,
    /**
     * 2.5rem === 40px
     */
    space6: 2.5,
    /**
     * 3rem === 48px
     */
    space7: 3,
    /**
     * 3.5rem === 56px
     */
    space8: 3.5,
    /**
     * 4rem === 64px
     */
    space9: 4,
    /**
     * 4.5rem === 72px
     */
    space10: 4.5,
    /**
     * 5rem === 80px
     */
    space11: 5,
    /**
     * 5.5rem === 88px
     */
    space12: 5.5,
    /**
     * 6rem === 96px
     */
    space13: 6,
    /**
     * 6.5rem === 104px
     */
    space14: 6.5,
    /**
     * 7rem === 112px
     */
    space15: 7,
    /**
     * 7.5rem === 120px
     */
    space16: 7.5,
    /**
     * 8rem === 128px
     */
    space17: 8,
  },
  /**
   * Predefined sizes for specific component types. Sizes are specified as em or rem units with base font size 16
   */
  iconSize: {
      /**
       * 0.813 rem === 13px
       */
      xxs: 0.813,
      /**
       * 1 rem === 16px
       */
      xs: 1,
      /**
       * 1.5 rem === 24px
       */
      s: 1.5,
      /**
       * 2 rem === 32px
       */
      m: 2,
      /**
       * 2.5 rem === 40px
       */
      l: 2.5,
      /**
       * 3 rem === 48px
       */
      xl: 3,
      /**
       * 4 rem === 64px
       */
      xxl: 4,
  },
  color: {
    /**
     * =========== Neutral colors ===========
     */

    /**
     * #262C30
     */
    neutralBlack: "#262C30",
    /**
     * #3B4A57
     */
    neutralNetworkGray: "#3B4A57",
    /**
     * #73808C
     */
    neutralIconGray: "#73808C",
    /**
     * #C2C9D1
     */
    neutralPassiveGray: "#C2C9D1",
    /**
     * #E6EBF1
     */
    neutralGray5: "#E6EBF1",
    /**
     * #F6F7F9
     */
    neutralGray2: "#F6F7F9",
    /**
     * #FFFFFF
     */
    neutralWhite: "#FFFFFF",
    /**
     * #DDE1E9
     */
    neutralOnPressGray: "#DDE1E9",
    /**
     * #EAEDF1
     */
    neutralOnHoverGray: "#EAEDF1",
    /**
     * #000000
     */
    neutralRealBlack: "#000000",

    /**
     * =========== Brand colors ===========
     */

    /**
     * #7D2332
     */
    brandRed: "#7D2332",
    /**
     * #FFA894
     */
    brandPeach: "#FFA894",
    /**
     * #EDBDBD
     */
    brandPink: "#EDBDBD",
    /**
     * #FFC226
     */
    brandBrightYellow: "#FFC226",
    /**
     * #F7CC7A
     */
    brandMutedYellow: "#F7CC7A",
    /**
     * #D9BFAD
     */
    brandCardboard: "#D9BFAD",
    /**
     * #5C2952
     */
    brandPurple: "#5C2952",
    /**
     * #1E3264
     */
    brandNavy: "#1E3264",
    /**
     * #A6EBEC
     */
    brandSky: "#A6EBEC",
    /**
     * #29545C
     */
    brandPetrol: "#29545C",
    /**
     * #38572B
     */
    brandForest: "#38572B",

    /**
     * =========== Signal colors ===========
     */

    /**
     * #0051C2
     */
    signalBlue: "#0051C2",
    /**
     * #008A00
     */
    signalGreen: "#008A00",
    /**
     * #E0F3D7
     */
    signalLightGreen: "#E0F3D7",
    /**
     * #F9F4EB
     */
    signalLightYellow: "#F9F4EB",
    /**
     * #BD2841
     */
    signalRed: "#BD2841",
    /**
     * #F8E7E7
     */
    signalLightRed: "#F8E7E7",
    /**
     * #D4E2F7
     */
    signalLightBlue: "#D4E2F7",
    /**
     * #004099
     */
    signalHoverBlue: "#004099",
    /**
     * #F7C3C3
     */
    signalHoverRed: "#F7C3C3",

    /**
     * =========== Background colors ===========
     */

    /**
     * #FCFCFD
     */
    backgroundUIGray: "#FCFCFD",
    /**
     * #262C30
     * Opacity 64%
     */
    backgroundModalGray: "rgba(38, 44, 48, 0.64)",
    /**
     * #E5EEFA
     */
    backgroundCTABlue: "#E5EEFA",
    /**
     * #E1F4E5
     */
    backgroundNotificationGreen: "#E1F4E5",
    /**
     * #FFF5E1
     */
    backgroundWarningYellow: "#FFF5E1",
    /**
     * #F6F7F9
     * Opacity 40%
     */
    backgroundExceptionalGray: "rgba(246, 247, 249, 0.4)",
    /**
     * #FFE7E7
     */
    backgroundAlertRed: "#FFE7E7",
  },
  /**
   * Font family for XYZ brand
   */
  fontFamily: {
    /**
     * Font for large headlines
     */
    Telegraf: "Telegraf",
    /**
     * Font for small headlines and body
     */
    Montserrat: "Montserrat",
  },
  /**
   * Common font sizes from small text for XYZ brand.
   * Units are in rem (base font size 16px). Pair these with line-heights
   * defined in the theme.
   *
   *  @example
   *  import styled, { css } from 'styled-components'
   *
   *  const HeadlineOne = styled.p(({ theme }) => css`
   *    font-family: ${theme.fontFamily.Telegraf};
   *    font-weight: ${theme.fontWeight.ultraBold};
   *    line-height: ${theme.lineHeight.headline.one};
   *    font-size: ${theme.fontSize.headline.one}rem;
   *  `)
   */
  fontSize: {
    headline: {
      /**
       * 5rem === 80px
       */
      one: 5,
      /**
       * 3rem === 48px
       */
      two: 3,
      /**
       * 2.4375rem === 39px
       */
      three: 2.4375,
      /**
       * 1.96875rem === 31.5px
       */
      four: 1.96875,
      /**
       * 1.5625rem === 25px
       */
      five: 1.5625,
      /**
       * 1.125rem === 18px
       */
      six: 1.125,
      /**
       * 1rem === 16px
       */
      seven: 1,
      /**
       * 0.875rem === 14px
       */
      eight: 0.875,
    },
    body: {
      /**
       * 1.25rem === 20px
       */
      one: 1.25,
      /**
       * 1.125rem === 18px
       */
      two: 1.125,
      /**
       * 1rem === 16px
       */
      three: 1,
      /**
       * 0.875rem === 14px
       */
      four: 0.875,
      /**
       * 0.8125rem === 13px
       */
      five: 0.8125,
    },
    label: {
      /**
       * 1.5rem === 24px
       */
      one: 1.5,
      /**
       * 1.125rem === 18px
       */
      two: 1.125,
      /**
       * 1rem === 16px
       */
      three: 1,
      /**
       * 1rem === 16px
       */
      four: 1,
      /**
       * 0.875rem === 14px
       */
      five: 0.875,
      /**
       * 0.625rem === 10px
       */
      six: 0.625,
    },
  },
  fontWeight: {
    /**
     * 100
     */
    thin: 100,
    /**
     * 300
     */
    light: 300,
    /**
     * 400
     */
    normal: 400,
    /**
     * 500
     */
    medium: 500,
    /**
     * 600
     */
    semiBold: 600,
    /**
     * 700
     */
    bold: 700,
    /**
     * 900
     */
    ultraBold: 900,
  },
  /**
   * Common line-height for text styles in XYZ. Line-height values are unitless.
   */
  lineHeight: {
    headline: {
      /**
       * 1.17
       */
      one: 1.17,
      /**
       * 1.17
       */
      two: 1.17,
      /**
       * 1.08
       */
      three: 1.08,
      /**
       * 1.14
       */
      four: 1.14,
      /**
       * 1.28
       */
      five: 1.28,
      /**
       * 1.556
       */
      six: 1.556,
      /**
       * 1.25
       */
      seven: 1.25,
      /**
       * 1.2
       */
      eight: 1.2,
    },
    body: {
      /**
       * 1.6
       */
      one: 1.6,
      /**
       * 1.556
       */
      two: 1.556,
      /**
       * 1.5
       */
      three: 1.5,
      /**
       * 1.43
       */
      four: 1.43,
      /**
       * 1.54
       */
      five: 1.54,
    },
    label: {
      /**
       * 1
       */
      one: 1,
      /**
       * 1
       */
      two: 1,
      /**
       * 1
       */
      three: 1,
      /**
       * 1
       */
      four: 1,
      /**
       * 1
       */
      five: 1,
      /**
       * 1
       */
      six: 1,
    },
  },
  borderRadius: {
    md: 2.75,
  },
  name: "xyz",
};
