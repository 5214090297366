import { PostiTheme } from "./posti";
import { XyzTheme } from "./xyz";

export * from "./posti";
export * from "./xyz";

export enum ThemeName {
  POSTI = "posti",
  XYZ = "xyz",
}

export const Theme = PostiTheme;

// Both interfaces should be exported as they will have different keys etc.
export type PostiThemeInterface = typeof PostiTheme;
export type XyzThemeInterface = typeof XyzTheme;

// The default theme interface should still be the PostiTheme
export type ThemeInterface = PostiThemeInterface;
